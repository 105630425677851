<template>
  <div id="Personal">
    <div class="header">
      <!--  -->
      <div  class="list disJust" @click="changePath('/EditMessage')">
        <div >
          <img :src="personalDetail.headimgurl ? Global.changeImg(personalDetail.headimgurl)  :'../assets/image/home/image.png'"/>
          <div >
            <p  class="first">{{personalDetail.member_info ?personalDetail.member_info.name :personalDetail.name}} 
              <img v-if="personalDetail.status == 5" src="../assets/image/findOut/blueTag.png" />
              <!-- <img v-else-if="personalDetail.status == 2 && personalDetail.is_approve == 1" src="../assets/image/findOut/yellowTag.png" /> -->
              <img v-else-if="personalDetail.status == 2 || personalDetail.status == 8 || personalDetail.is_activate == 0" src="../assets/image/findOut/grayTag.png" />
            </p>
            <p  class="second" v-if="personalDetail.status == 8 && personalDetail.is_activate == 0">完善个人资料，激活会员资格</p>
            <p  class="second" v-else>点击编辑个人资料</p>
          </div>
        </div>
        <span>
          <!-- <span @click.stop="changePath('/CertifiedMember')" v-if="personalDetail.status == 2 && personalDetail.is_approve == 0" >去认证</span>
          <span v-if="personalDetail.status == 2 && personalDetail.is_approve == 2" >认证中</span> -->
          <van-icon name="arrow" color="#ECEDF7" />
        </span>
      </div>
      <div class="canve disJust">
        <div @click="changePath('/SayHelloTo')"><p>{{personalDetail.initiative_count}}</p><p>我打招呼的</p></div>
        <div @click="changePath('/SayHelloTo?activeName=b')"><p>{{personalDetail.wts_log_count}}</p><p>向我打招呼的</p></div>
   <!-- changePath('/MyQuesAnswer') -->
        <div @click="$toast('本功能暂未开放')"  ><p>{{personalDetail.user_quiz_count}}</p><p>我的问答</p></div>
        <div @click="changePath('/MyCollection')" ><p>{{personalDetail.collect_count}}</p><p>我的收藏</p></div>
      </div>
      <div class="vip disJust" @click="changePath('/MemberProduct')">
        <div>
          <img src="../assets/image/main/vipWhite.png" />
          <p>{{(personalDetail.status== 2 || personalDetail.status== 8) ?'会员有效期至'+personalDetail.validate_end.split(' ')[0] : '开通会员，享受更多功能'}}</p>
        </div>
        <div>{{(personalDetail.status== 2 || personalDetail.status== 8) ?'会员续费' : '开通会员'}}<span><van-icon name="down" color="#323232" style="transform: rotate(-90deg);font-size:0.12rem" /></span></div>
      </div>
      <div class="black"></div>
      <div class="request disJust">
        <!-- changePath('/Invitational') -->
        <div @click="changePath('/Invitational')">
          <div ><p  class="first">邀请有礼</p><p  class="second">邀好友赚现金 ></p></div>
          <img src="../assets/image/main/wallet.png" />
        </div>
        <div @click="changePath('/MemberProduct?dhm=dhm')">
          <div ><p  class="first">兑换VIP会员</p><p  class="second">兑换码换购 ></p></div>
          <img src="../assets/image/main/good.png" />
        </div>
      </div>
    </div>
    <div class="oerder">
      <div>
        <p>我的订单</p>
        <div>
          <div @click="changePath('/OrderForm?type=1')"><img src="../assets/image/main/order1.png" style="width:0.2rem" /><p>订单查询</p></div>
          <div @click="changePath('/OrderForm?type=2')" ><img src="../assets/image/main/order2.png" /><p>待支付</p></div>
          <!-- <div @click="changePath('/OrderForm?type=3')" ><img src="../assets/image/main/order3.png" /><p>已预约</p></div> -->
          <!-- <div @click="changePath('/OrderForm?type=4')" ><img src="../assets/image/main/order4.png" /><p>待参加</p></div> -->
        </div>
      </div>
    </div>
   <div class="oerder">
      <div>
        <p>其他服务</p>
        <div>
          <div @click="show = true"><img src="../assets/image/main/order5.png" /><p>联系我们</p></div>
          <div @click="changePath('/Setting')"><img src="../assets/image/main/order6.png" /><p>隐私设置</p></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    
    <van-popup v-model="show" style="background:none">
      <img class="main" src="img/kf2.png" />
      <div class="close">
        <span @click="show = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "Personal",
  data(){
    return{
      show:false,
      personalDetail:'',//详情
    }
  },
  mounted() {
    // 用户会员信息 1:非会员 2:会员 3:已过期 4:已锁定5:官方  //1认证会员
    this.axios({
      method: 'GET',
      url:'/user/getUserInfo',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.personalDetail = res.data.data
      }
    })
  },
  methods:{
    changePath(path){
      this.path = path
      this.$router.push(path)
    }
  }
};
</script>
<style lang="less" scoped>
#Personal {
  padding-bottom: 0.6rem;
    .main{
      width: 2.63rem;
    }
    .close{
      text-align: center;
      >span{
        width: 0.35rem;
        height: 0.35rem;
        background: #6e6f72;
        border-radius: 50%;
        margin-top: 0.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
      }
    }

  .oerder{
    margin-top: 0.11rem;
    padding: 0 0.15rem;
    >div{
      overflow: auto;
      >p{
        margin: 0.15rem 0;
        font-size: 0.15rem;
        color: #F6F7FD;
      }
      >div{
        display: flex;
        text-align: center;
        >div{
          width: 25%;
          >img{
            height: 0.25rem;
            margin: auto;
          }
          >p{
            margin: 0.1rem 0 0.15rem;
            font-size: 0.14rem;
            color: #ECEDF7;
            text-align: center;
          }
        }
      }
    }

  }
  .header{
    overflow: hidden;
    .request{
      margin-bottom: 0.15rem;
      >div:last-child{
        margin-left: 0.11rem;
        background: rgba(227, 159, 41, 0.22);
        .second{
          color: rgba(252, 169, 26, 0.69);
        }
      }
      >div{
        border-radius: 5px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 0.15rem;
        background: rgba(229, 89, 89, 0.19);
        >div{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          >p{
            text-align: left;
          }
        }
        .first{
          color: #ECEDF7;
          font-size: 0.14rem;
        }
        .second{
          font-size: 0.12rem;
          color: #E55959;
        }
        >img{
          height: 0.4rem;
        }
      }
    }
    .black{
      height: 0.2rem;
      background: #23252E;
      box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.16);
    }
    .vip{
      align-items: center;
      >div:last-child{
        background: linear-gradient(270deg, #2E2E2E 0%, #494949 100%);
        height: 0.3rem;
        border-radius: 0.3rem;
        line-height: 0.3rem;
        padding: 0 0.14rem;
        color: #F6F7FD;
        font-size: 0.12rem;
        >span{
          margin-left: 0.07rem;
          background: linear-gradient(90deg, #E6D0B9 0%, #D9B791 100%);
          border-radius: 50%;
        }
      }
      >div:first-child{
        display: flex;
        flex-direction: column;
        >p{
          font-size: 0.12rem;
          color: #3D404F;
        }
        >img{
          width: 1.09rem;
          margin-bottom: 0.06rem;
        }
      }
      padding: 0.15rem 0.12rem 0.09rem 0.15rem;
      background: url(../assets/image/main/vipBg.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 0.2rem;
    }
    .canve{
      >div{
        text-align: center;
        >p:first-child{
          color: #F6F7FD;
          font-weight: bold;
          font-size: 0.15rem;
          margin-bottom: 0.07rem;
        }
        >p:last-child{
          color: #9091A3;
          font-size: 0.13rem;
        }
      }
    }
    .list{
      padding: 0.15rem  0 0.18rem;
      align-items: center;
      >span{
        >span{
          color: #ECEDF7;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        .first{
          color: #F6F7FD;
          font-size: 0.16rem;
          margin-bottom: 0.04rem;
          display: flex;
          align-items: center;
          >img{
            height: 0.18rem;
            margin-left: 0.08rem;
          }
        }
        .second{
          color: #727387;
        }
       
        >img{
          width: 0.65rem;
          height: 0.65rem;
          margin-right: 0.2rem;
          box-sizing: border-box;
          border: 2px solid white;
          border-radius: 50%;
        }
      }
    }
  }
  .disJust{
      display: flex;
      justify-content: space-between;
    }
  >div{
    background: #23252E;
    padding: 0 0.15rem;
  }
}
</style>